<template>
    <div>
        <page-title>{{ $t('promo_banners.list_page_title') }}</page-title>
        <promo-banner-table>
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.PROMO_BANNERS_CREATE"
                ></create-button>
            </template>
        </promo-banner-table>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PageTitle from '@/components/layout/components/pageTitle'
import PromoBannerTable from '@/components/pages/promoBanner/Table'
import CreateButton from '@/components/form/Actions/CreateButton'

export default {
    components: {
        CreateButton,
        PageTitle,
        PromoBannerTable,
    },
    computed: {
        routeType: () => routeType,
    },
}
</script>
