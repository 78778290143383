<template>
    <data-iterator ref="iterator" :api="api" :filters.sync="filters">
        <template v-slot:filters="{ busy }">
            <table-filter
                v-model="filters"
                :fields="headers"
                :busy="busy"
                hide-filter-box
            ></table-filter>
        </template>

        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :headers="headers"
                :items="items"
                :filters.sync="filters"
                row-select
                row-action
                @onRowSelected="onRowSelected"
            >
                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <edit-button :to="getEditLink(item)"></edit-button>
                    <delete-action
                        :entity-name="item.name"
                        :entity-id="item.id"
                        :api="api"
                        strong
                        @deleted="onEntityDeleted"
                        @failed="onEntityDeleteFailed"
                    ></delete-action>
                </template>
            </d-table>
        </template>
    </data-iterator>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from './mixins/Headers'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],
    data() {
        return {
            api: RestApiType.PROMO_BANNERS,
        }
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.PROMO_BANNERS_EDIT,
                params: { id: item.id },
            }
        },
    },
}
</script>
